import { useEffect } from "react";
import { loadFacebookSDK } from "./facebookSdk";

const FacebookLoginButton = ({
  onSuccess,
  onFailure,
  appId, 
  fields = "id,name,email",
  scope = "email",
  buttonText,
  cssClass,
}) => {
  useEffect(() => {
    loadFacebookSDK(appId);
  }, [appId]);

  const handleFacebookLogin = () => {
    if (!window.FB) {
      console.error("Facebook SDK not loaded yet.");
      onFailure("Facebook SDK not initialized.");
      return;
    }
  
    window.FB.login(
      (response) => {
        if (response?.authResponse) {
          const {
            userID,
            accessToken,
            expiresIn,
            signedRequest,
            data_access_expiration_time,
          } = response?.authResponse;
          window.FB.api("/me", { fields }, (userInfo) => {
            if (userInfo?.error) {
              onFailure(userInfo?.error?.message || "Failed to fetch user data.");
            } else {
              const result = {};
              if (fields?.includes("name")) result.name = userInfo?.name || null;
              if (fields?.includes("email")) result.email = userInfo?.email || null;
              if (fields?.includes("picture")) result.picture = userInfo?.picture?.data?.url || null;
  
              onSuccess({
                userID,
                accessToken,
                expiresIn,
                signedRequest,
                data_access_expiration_time,
                graphDomain: response?.graphDomain || "facebook",
                id: userInfo?.id || null,
                ...result,
              });
            }
          });
        } else {
          onFailure("User canceled login or did not fully authorize.");
        }
      },
      {
        scope,
        return_scopes: true,
        auth_type: "rerequest",
        enable_profile_selector: true,
      }
    );
  };
  

  return (
    <button
      type="button"
      className={cssClass} 
      onClick={handleFacebookLogin}
    >
      {buttonText}
    </button>
  );
};

export default FacebookLoginButton;
