import React from "react";
import TermsAndConditionsComponent from "./termsAndConditions";
import { connect } from "react-redux";

const TermsAndConditions = (props) => {
  const { productList } = props;
  return <TermsAndConditionsComponent productList={productList} />;
};
const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData,
    productListSingle: state.getProductListSingle.productListSingleData,
  };
};

export default connect(mapStateToProps, null)(TermsAndConditions);
