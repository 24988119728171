import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";

export const iPQualityScore = createAsyncThunk(
  "iPQualityScore",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/setting/ip-quality-status`,
        data
      );
      return response;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const iPQualityScoreSlice = createSlice({
  name: "iPQualityScore",
  initialState: {
    isLoading: false,
    result: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(iPQualityScore.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(iPQualityScore.fulfilled, (state, action) => {
        state.isLoading = false;
        state.result = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(iPQualityScore.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default iPQualityScoreSlice.reducer;
