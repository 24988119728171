import React, { useEffect, useState } from "react";
import "./header.scss";
import MainLogo from "../../assets/header/Logo.svg";

import { BsFillPersonFill } from "react-icons/bs";
import { HiMenu } from "react-icons/hi";
import { Dropdown, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import { languages } from "../../utils/commonUtils";
import ReactCountryFlag from "react-country-flag";
import useScrollToTop from "../customHook/useScrollToTop";
import LanguageModal from "../common/languageModal.jsx";

const HeaderComponent = (props) => {
  const { page, logoLoad } = props;
  useScrollToTop();
  const cookie = new Cookies();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [lang, setLang] = useState();
  const [showLogo, setShowLogo] = useState("gb");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [updatedFlag, setUpdatedFlag] = useState();
  const token = cookie.get("token");

  const hideRoute =
    ["cookie-policy", "privacy-policy", "terms"].includes(
      window.location.pathname.split("/")[2]
    ) && token;

  useEffect(() => {
    const cookieLang = cookie.get("lang");
    const browserLanguage = navigator.language || navigator.userLanguage;
    setLang(cookieLang ? cookieLang : browserLanguage);
    // eslint-disable-next-line
  }, [cookie, window.location]);

  const items = [
    {
      label: <Link to={`/${lang}/faq`}>{t("MENU_FAQ")}</Link>,
      key: "0",
    },
    {
      label: <Link to={`/${lang}/pricing`}>{t("FOOTER_PRICING")}</Link>,
      key: "1",
    },
    {
      label: <Link to={`/${lang}/contact`}>{t("MENU_CONTACT")}</Link>,
      key: "3",
    },
    {
      label: <Link to={`/${lang}/unsubscribe`}>{t("MENU_UNSUBSCRIBE")}</Link>,
      key: "4",
    },
    {
      label: (
        <div className="header-login-res-btn">
          <Link to={`/${lang}/login`}>
            <button>
              <BsFillPersonFill />
              {t("LOGIN")}
            </button>
          </Link>
        </div>
      ),
      key: "5",
    },
  ];
  const loggedItem = [
    {
      label: (
        <div className="header-login-res-btn">
          <Link to={`/${lang}/dash`}>
            <button>
              <BsFillPersonFill />
              {t("PR_DASHBOARD")}
            </button>
          </Link>
        </div>
      ),
      key: "0",
    },
  ];
  const filteredItems = hideRoute ? { items: loggedItem } : { items: items };
  useEffect(() => {
    const find = languages.find((item) => {
      return item.code === window.location.pathname.split("/")[1];
    });
    if (find) {
      cookie.set("langlogo", find.flagCode);
      setUpdatedFlag(find.flagCode);
    }
    // eslint-disable-next-line
  }, [lang, window.location.pathname, cookie]);

  useEffect(() => {
    document.body.classList.toggle("no-scroll", isModalOpen);
    return () => document.body.classList.remove("no-scroll");
  }, [isModalOpen]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    // eslint-disable-next-line
  }, []);

  //  disable language for temp
  const handleChangeLanguage = (selectedLang, flagCode) => {
    i18n.changeLanguage(selectedLang);
    const newPath = window.location.pathname.split("/")[2]
      ? `/${selectedLang}/${window.location.pathname.split("/")[2]}`
      : `/${selectedLang}`;
    navigate(newPath);
    setIsModalOpen(false);
    setLang(selectedLang);
    cookie.set("lang", selectedLang, { path: "/", sameSite: true });
    cookie.set("langlogo", flagCode, { path: "/", sameSite: true });
  };

  useEffect(() => {
    setShowLogo(updatedFlag);
    // eslint-disable-next-line
  }, [cookie, window.location.pathname]);
  return (
    <header className="header-main-section">
      <div
        className={`header ${
          page === "track" || page === "trace" ? "track-header" : ""
        }`}
      >
        <div className="header-df">
          <div className="header-main-logo">
            <div className="header-logo">
              {logoLoad ? (
                <img
                  src={MainLogo}
                  alt="Main Logo"
                  width={190}
                  height={page === "track" || page === "trace" ? 40 : 45}
                  style={{ cursor: "pointer" }}
                  loading="lazy"
                />
              ) : (
                <Link to={`/${lang}/`}>
                  {" "}
                  <img
                    src={MainLogo}
                    alt="Main Logo"
                    width={190}
                    height={page === "track" || page === "trace" ? 40 : 45}
                    style={{ cursor: "pointer" }}
                    loading="lazy"
                  />
                </Link>
              )}
            </div>
          </div>
          <div className="header-menu-nd-login">
            {!hideRoute && (
              <ul className="header-menu m-0 p-0">
                <li
                  className={
                    page === "track" || page === "trace" ? "track-menu" : ""
                  }
                >
                  <Link to={`/${lang}/faq`}>{t("MENU_FAQ")}</Link>
                </li>
                <li
                  className={
                    page === "track" || page === "trace" ? "track-menu" : ""
                  }
                >
                  <Link to={`/${lang}/pricing`}>{t("FOOTER_PRICING")}</Link>
                </li>

                <li
                  className={
                    page === "track" || page === "trace" ? "track-menu" : ""
                  }
                >
                  <Link to={`/${lang}/contact`}>{t("MENU_CONTACT")}</Link>
                </li>
                <li
                  className={
                    page === "track" || page === "trace" ? "track-menu" : ""
                  }
                >
                  <Link to={`/${lang}/unsubscribe`}>
                    {t("MENU_UNSUBSCRIBE")}
                  </Link>
                </li>
              </ul>
            )}

            {/* disable language for temp  */}
            <div className="header-lang-drop">
              <ul className="m-0 p-0">
                <li>
                  {showLogo && (
                    <div onClick={() => setIsModalOpen(true)}>
                      <ReactCountryFlag
                        alt="Country Flag"
                        countryCode={showLogo}
                        svg
                        style={{
                          width:
                            page === "track" || page === "trace"
                              ? "24px"
                              : "27px",
                          height:
                            page === "track" || page === "trace"
                              ? "24px"
                              : "27px",
                        }}
                      />
                    </div>
                  )}
                </li>
              </ul>
            </div>
            <div className="header-login-btn">
              <Link to={`/${lang}/${hideRoute ? "dash" : "login"}`}>
                <button
                  className="hl_cta_wrap"
                  style={{
                    padding:
                      page === "track" || page === "trace" ? "7px 12px" : "",
                  }}
                >
                  <BsFillPersonFill />{" "}
                  {hideRoute ? t("PR_DASHBOARD") : t("LOGIN")}
                </button>
              </Link>
            </div>
            <div
              className={`responsive-header-btn ${
                hideRoute && "token-hide-route"
              }`}
            >
              <Dropdown
                menu={filteredItems}
                trigger={["click"]}
                placement="bottomRight"
              >
                <span onClick={(e) => e.preventDefault()}>
                  <Space>
                    <HiMenu />
                  </Space>
                </span>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      <LanguageModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        currentLang={lang}
        handleChangeLanguage={handleChangeLanguage}
      />
    </header>
  );
};

export default HeaderComponent;
