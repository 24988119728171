import React from "react";
import { BrowserRouter as Router } from "react-router-dom";

const AppProvider = ({ children }) => {
  return (
    <React.Suspense>
      <Router
        future={{
          v7_startTransition: true,
          v7_relativeSplatPath: true,
        }}
      >
        {children}
      </Router>
    </React.Suspense>
  );
};

export default AppProvider;
