import React from "react";
import { Modal } from "antd";
import ReactCountryFlag from "react-country-flag";
import { t } from "i18next";
import { languages, renderRTLClass } from "../../utils/commonUtils";

const LanguageModal = ({
  isOpen,
  onClose,
  currentLang,
  handleChangeLanguage,
}) => {
  return (
    <Modal
      open={isOpen}
      onCancel={onClose}
      okButtonProps={{ style: { display: "none" } }}
      cancelButtonProps={{ style: { display: "none" } }}
      className={renderRTLClass()}
    >
      <div className="modal-container">
        <div className="modal-title">
          <p>{t("LANGUAGE")}</p>
        </div>
        <div className="modal-languages">
          <div className="df-modal-language">
            {languages.map((item, index) => (
              <div
                className={`lang ${currentLang === item.code && "active"}`}
                onClick={() => handleChangeLanguage(item.code, item.flagCode)}
                key={index}
              >
                <div className="flag">
                  <ReactCountryFlag
                    countryCode={item.flagCode}
                    svg
                    style={{
                      width: "22px",
                      height: "22px",
                    }}
                  />
                </div>
                <span>{item.name}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default LanguageModal;