import React, { useState } from "react";
import "./faq.scss";
import plusIcon from "../../assets/home/plus.svg";
import minusIcon from "../../assets/home/minus.svg";
import { useTranslation } from "react-i18next";
import { Cookies } from "react-cookie";
import useScrollToTop from "../customHook/useScrollToTop";

const FAQComponent = (props) => {
  const { productList } = props;
  const [collapseTab, setCollapseTab] = useState(0);
  const { t } = useTranslation();
  const cookie = new Cookies();
  const cur = cookie.get("currency");
  useScrollToTop();

  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const TrialCurr = trialProduct?.currency_options.find(
    (item) => item.parameter === cur
  );

  const subscriptionProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const subscriptionProductPrice = subscriptionProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  return (
    <div className="faq-main-section">
      <div className="back-grad-title">
        <div className="pricing-main-title">
          {t("FAQ_ASKED_QUESTION_TITLE")}
        </div>
      </div>
      <div className="faq-bg">
        <div className="faq-container">
          <div className="faq-contant">
            <div className="bg-section">
              <div className="frequently-asked-question">
                <div className="question-collapse">
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 1 ? 0 : 1)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_1_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 1 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 1 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_1_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 2 ? 0 : 2)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_2_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 2 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 2 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_2_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 3 ? 0 : 3)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_3_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 3 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 3 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_3_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 4 ? 0 : 4)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_4_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 4 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 4 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_4_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 5 ? 0 : 5)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_5_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 5 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 5 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_5_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 6 ? 0 : 6)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_6_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 6 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 6 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_6_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 7 ? 0 : 7)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_7_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 7 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 7 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_7_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 8 ? 0 : 8)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_8_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 8 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 8 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_8_TEXT")}</p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 9 ? 0 : 9)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_9_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 9 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 9 && (
                      <div className="open-tab-desc">
                        <p>
                          {t("FAQ_ASKED_QUESTION_TAB_9_TEXT", {
                            trial_price: TrialCurr.symbol + TrialCurr.amount,
                            standart_price:
                              subscriptionProductPrice.symbol +
                              subscriptionProductPrice.amount,
                          })}
                        </p>
                      </div>
                    )}
                  </div>
                  <div
                    className="collapse-box"
                    onClick={() => setCollapseTab(collapseTab === 10 ? 0 : 10)}
                  >
                    <div className="collapse-box-df-title">
                      <div className="collapse-box-title">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_10_TITLE")}</p>
                      </div>
                      <div className="collapse-box-icon">
                        {collapseTab === 10 ? (
                          <div>
                            <img src={minusIcon} alt="Minus" />
                          </div>
                        ) : (
                          <div>
                            <img
                              src={plusIcon}
                              alt="Plus"
                              width={24}
                              height={25}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    {collapseTab === 10 && (
                      <div className="open-tab-desc">
                        <p>{t("FAQ_ASKED_QUESTION_TAB_10_TEXT")}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQComponent;
