import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const updateSetting = createAsyncThunk(
  "updateSetting",
  async (data, { rejectWithValue }) => {
    try {
      const response = await AxiosInstance.put(`/user/settings`, data);
      return response.data;
    } catch (error) {
      if (error.response) {
        return rejectWithValue(error.response);
      } else {
        return rejectWithValue({
          message: error.message || "An unknown error occurred",
        });
      }
    }
  }
);

const updateSettingSlice = createSlice({
  name: "updateSetting",
  initialState: {
    isLoading: false,
    updateSettingData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateSetting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateSetting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateSettingData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default updateSettingSlice.reducer;
