import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const updateUserLanguage = createAsyncThunk("updateUserLanguage", async (data) => {
  try {
    const response = await AxiosInstance.put(`user/change_language`, data);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const updateUserLanguageSlice = createSlice({
  name: "updateUserLanguage",
  initialState: {
    isLoading: false,
    updateUserLanguageData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateUserLanguage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateUserLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.updateUserLanguageData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(updateUserLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default updateUserLanguageSlice.reducer;
