import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const locationList = createAsyncThunk("locationList", async () => {
  try {
    const response = await AxiosInstance.get(`/location`);
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data.message;
    } else {
      throw error;
    }
  }
});

const locationListSlice = createSlice({
  name: "locationList",
  initialState: {
    isLoading: false,
    locationListData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(locationList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(locationList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.locationListData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(locationList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default locationListSlice.reducer;
