import React from "react";
import HomePageComponent from "./homePage";
import { connect } from "react-redux";

const HomePage = (props) => {
  const { productList, contant, from } = props;
  return (
    <HomePageComponent
      productList={productList}
      contant={contant}
      from={from}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    productList: state.getProductList.productListData,
  };
};

export default connect(mapStateToProps, null)(HomePage);
