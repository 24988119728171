import React from "react";
import "./termsAndConditions.scss";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { Cookies } from "react-cookie";
import useScrollToTop from "../customHook/useScrollToTop";
import imgTextNew from "../../assets/imgTextNew.svg";
import streetAddressResponsiveNew from "../../assets/streetAddressResponsiveNew.svg";
import AddressNew from "../../assets/AddressNew.svg";

const TermsAndConditionsComponent = (props) => {
  const { productList } = props;
  const cookies = new Cookies();
  const { t } = useTranslation();
  const cur = cookies.get("currency");
  const trialProduct = productList?.data.find((item) => item.title === "trial");
  const standardProduct = productList?.data.find(
    (item) => item.title === "standard"
  );
  const TrialCurr = trialProduct?.currency_options.find(
    (item) => item.parameter === cur
  );
  const standardCurr = standardProduct?.currency_options.find(
    (item) => item.parameter === cur
  );

  useScrollToTop();
  return (
    <div className="termsAndConditions-main-section">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("TC_TITLE")}</div>
      </div>
      <div className="container">
        <div className="dashboard-box">
          <div className="card sh mt-4">
            <div className="docs-section">
              <h5>{t("TC_1_OWNERSHIP")}</h5>
              <Markup content={t("TC_1_OWNERSHIP_DESCRIPTION_1")} />
              <div className="address_line_set">
                <p>{t("TC_1_OWNERSHIP_DESCRIPTION_1_1")}</p>
                <img src={imgTextNew} aria-hidden="true" alt="address" width={295} height={15}/>
                <p>{t("TC_1_OWNERSHIP_DESCRIPTION_1_2")}</p>
                <img
                  className="d--block"
                  src={AddressNew}
                  aria-hidden="true"
                  alt="address"
                  width={452} 
                  height={15}
                />
                <img
                  className="d--none"
                  src={streetAddressResponsiveNew}
                  aria-hidden="true"
                  alt="address"
                  width={275} 
                  height={30}
                />
              </div>
              <Markup content={t("TC_1_OWNERSHIP_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_2_ACCEPTANCE")}</h5>
              <Markup content={t("TC_2_ACCEPTANCE_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_3_DOC")}</h5>
              <Markup content={t("TC_3_DOC_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_4_EXTERNAL_LINK")}</h5>
              <Markup content={t("TC_4_EXTERNAL_LINK_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_5_AGE")}</h5>
              <Markup content={t("TC_5_AGE_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_6_IAIP")}</h5>
              <Markup content={t("TC_6_IAIP_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_7_USER_CONTENT")}</h5>
              <Markup content={t("TC_7_USER_CONTENT_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_8_PRICE_AND_TEXES")}</h5>
              <Markup
                content={t("TC_8_PRICE_AND_TEXES_DESCRIPTION", {
                  trial_price: TrialCurr?.symbol + TrialCurr?.amount,
                })}
              />
            </div>
            <div className="docs-section">
              <h5>{t("TC_9_PAYMENT_METHODS")}</h5>
              <Markup content={t("TC_9_PAYMENT_METHODS_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_10_VO")}</h5>
              <Markup content={t("TC_10_VO_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_11_GAURANTEES")}</h5>
              <Markup content={t("TC_11_GAURANTEES_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_12_RESPONSIBILITY")}</h5>
              <Markup content={t("TC_12_RESPONSIBILITY_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_13_SECURITY")}</h5>
              <Markup content={t("TC_13_SECURITY_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_14_SAR")}</h5>
              <Markup
                content={t("TC_14_SAR_DESCRIPTION", {
                  trial_price: TrialCurr?.symbol + TrialCurr?.amount,
                  standard_price: standardCurr?.symbol + standardCurr?.amount,
                })}
              />
            </div>
            <div className="docs-section">
              <h5>{t("TC_15_WITHDRAWAL")}</h5>
              <Markup content={t("TC_15_WITHDRAWAL_DESCRIPTIONS")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_16_MAN")}</h5>
              <Markup content={t("TC_16_MAN_DESCRIPTIONS")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_17_CAAC")}</h5>
              <Markup content={t("TC_17_CAAC_DESCRIPTIONS")} />
            </div>
            <div className="docs-section">
              <h5>{t("TC_18_CONTACT")}</h5>
              <Markup content={t("TC_18_CONTACT_DESCRIPTIONS")} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsComponent;
