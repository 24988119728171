import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const getSpreedlyCurrency = createAsyncThunk(
  "getSpreedlyCurrency",
  async () => {
    try {
      const response = await AxiosInstance.get(`/pricing/list/trial_spreedly`);
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const getSpreedlyCurrencySlice = createSlice({
  name: "getSpreedlyCurrency",
  initialState: {
    isLoading: false,
    getSpreedlyCurrencyData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSpreedlyCurrency.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSpreedlyCurrency.fulfilled, (state, action) => {
        state.isLoading = false;
        state.getSpreedlyCurrencyData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(getSpreedlyCurrency.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default getSpreedlyCurrencySlice.reducer;
