import { notification } from "antd";
import {
  CheckCircleOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";
import { Cookies } from "react-cookie";
const Notify = (type, message, description) => {
  let icon;
  const cookies = new Cookies();
  const lang = cookies.get("lang");
  const isRenderaRTL = (lang) => {
    const rtlLanguages = ["ar", "he"];
    return rtlLanguages.includes(lang);
  };
  if (type === "success") {
    icon = <CheckCircleOutlined style={{ color: "#52c41a" }} />;
  } else if (type === "error") {
    icon = <ExclamationCircleOutlined style={{ color: "#f5222d" }} />;
  } else if (type === "warning") {
    icon = <ExclamationCircleOutlined style={{ color: "#faad14" }} />;
  } else {
    icon = <ExclamationCircleOutlined style={{ color: "#faad14" }} />;
  }

  notification.open({
    message: `${message}`,
    description: `${description}`,
    icon,
    duration: 3,
    placement: `${isRenderaRTL(lang) ? "topLeft" : "topRight"}`,
  });
};

export default Notify;
