import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
export const createSubscription = createAsyncThunk(
  "createSubscription",
  async (data) => {
    try {
      const response = await AxiosInstance.post(
        `/subscription/create-subscription`,
        data
      );
      return response.data;
    } catch (error) {
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const createSubscriptionSlice = createSlice({
  name: "createSubscription",
  initialState: {
    isLoading: false,
    createSubscriptionData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(createSubscription.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createSubscription.fulfilled, (state, action) => {
        state.isLoading = false;
        state.createSubscriptionData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(createSubscription.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },  
});

export default createSubscriptionSlice.reducer;
