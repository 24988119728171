import React from "react";
import "./privacyPolicy.scss";
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import useScrollToTop from "../customHook/useScrollToTop";
import imgTextNew from "../../assets/imgTextNew.svg";
import streetAddressResponsiveNew from "../../assets/streetAddressResponsiveNew.svg";
import AddressNew from "../../assets/AddressNew.svg";

const PrivacyPolicyComponent = () => {
  useScrollToTop();
  const { t } = useTranslation();

  return (
    <div className="privacyPolicy-main-section">
      <div className="back-grad-title">
        <div className="pricing-main-title">{t("PP_TITLE")}</div>
      </div>
      <div className="container">
        <div className="dashboard-box">
          <div className="card mt-4">
            <div className="docs-section">
              <h5>{t("PP_1_DESCRIPTION")}</h5>
              <Markup content={t("PP_1_DESCRIPTION_INFO")} />
            </div>
            <div className="docs-section">
              <h5>{t("PP_2_INFO_COLL")}</h5>
              <Markup content={t("PP_2_INFO_COLL_DESCRIPTION_1")} />
              <p>
                <Markup content={t("PP_2.1_INFO")} />
              </p>
              <p>{t("PP_2.1_INFO_DESCRIPTION")}</p>
              <ul>
                <li>{t("PP_2.1_INFO_ITM_1")}</li>
                <li>{t("PP_2.1_INFO_ITM_2")}</li>
                <li>{t("PP_2.1_INFO_ITM_3")}</li>
                <li>{t("PP_2.1_INFO_ITM_4")}</li>
                <li>{t("PP_2.1_INFO_ITM_5")}</li>
                <li>
                  <Markup content={t("PP_2.1_INFO_ITM_6")} />
                </li>
              </ul>
              <p>
                <Markup content={t("PP_2.2_INFO")} />
              </p>
              <p>{t("PP_2.2_INFO_DESCRIPTION")}</p>
              <ul>
                <li>
                  <Markup content={t("PP_2.2_INFO_ITM_1")} />
                </li>
                <li>
                  <Markup content={t("PP_2.2_INFO_ITM_2")} />
                </li>
                <li>
                  <Markup content={t("PP_2.2_INFO_ITM_3")} />
                </li>
                <li>
                  <Markup content={t("PP_2.2_INFO_ITM_4")} />
                </li>
                <li>
                  <Markup content={t("PP_2.2_INFO_ITM_5")} />
                </li>
              </ul>
              <p>
                <Markup content={t("PP_2.3_INFO")} />
              </p>
              <p>{t("PP_2.3_INFO__DESCRIPTION")}</p>
              <ul>
                <li>
                  <Markup content={t("PP_2.3_INFO_ITM_1")} />
                </li>
                <li>
                  <Markup content={t("PP_2.3_INFO_ITM_2")} />
                </li>
              </ul>
              <p>
                <Markup content={t("PP_2.4_INFO")} />
              </p>
              <p>
                <Markup content={t("PP_2.4_INFO_DESCRIPTION_1")} />
              </p>
              <p>
                <Markup content={t("PP_2.4_INFO_DESCRIPTION_2")} />
              </p>
              <p>
                <Markup content={t("PP_2.4_INFO_DESCRIPTION_3")} />
              </p>
              <p>
                <Markup content={t("PP_2.4_INFO_DESCRIPTION_4")} />
              </p>
            </div>
            <div className="docs-section">
              <h5>{t("PP_3_RIGHT_AND_PURPOSE")}</h5>
              <Markup content={t("PP_3_RIGHT_AND_PURPOSE_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("PP_4_AGE")}</h5>
              <Markup content={t("PP_4_AGE_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("PP_5_UOD")}</h5>
              <Markup content={t("PP_5_UOD_DESCRIPTION_1")} />
              <ul>
                <li>
                  <Markup content={t("PP_5_UOD_ITM_1")} />
                </li>
                <li>
                  <Markup content={t("PP_5_UOD_ITM_2")} />
                </li>
                <li>
                  <Markup content={t("PP_5_UOD_ITM_3")} />
                </li>
                <li>
                  <Markup content={t("PP_5_UOD_ITM_4")} />
                </li>
                <li>
                  <Markup content={t("PP_5_UOD_ITM_5")} />
                </li>
              </ul>
              <p>
                <Markup content={t("PP_5_UOD_DESCRIPTION_2")} />
              </p>
              <ul>
                <li>
                  <Markup content={t("PP_5_UOD_ITM_6")} />
                </li>
                <li>
                  <Markup content={t("PP_5_UOD_ITM_7")} />
                </li>
              </ul>
              <p>
                <Markup content={t("PP_5_UOD_DESCRIPTION_3")} />
              </p>
            </div>
            <div className="docs-section">
              <h5>{t("PP_6_DELETE_ACCOUNT")}</h5>
              <Markup content={t("PP_6_DELETE_ACCOUNT_DESC")} />
            </div>
            <div className="docs-section">
              <h5>{t("PP_7_SHWTP")}</h5>
              <Markup content={t("PP_7_SHWTP_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("PP_8_SECURITY_MEASURED")}</h5>
              <Markup content={t("PP_8_SECURITY_MEASURED_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("PP_9_CPP")}</h5>
              <Markup content={t("PP_9_CPP_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <h5>{t("PP_10_CONTACT")}</h5>
              <Markup content={t("PP_10_CONTACT_DESCRIPTION")} />
            </div>
            <div className="docs-section">
              <div className="address_line_set">
                <p>{t("TC_1_OWNERSHIP_DESCRIPTION_1_1")}</p>
                <img src={imgTextNew} aria-hidden="true" alt="address" />
                <p>{t("TC_1_OWNERSHIP_DESCRIPTION_1_2")}</p>
                <img
                  className="d--block"
                  src={AddressNew}
                  aria-hidden="true"
                  alt="address"
                />
                <img
                  className="d--none"
                  src={streetAddressResponsiveNew}
                  aria-hidden="true"
                  alt="address"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicyComponent;
