import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import AxiosInstance from "../../utils/axios";
import { logAPI } from "../../utils/commonUtils";
export const subscriptionCreate = createAsyncThunk(
  "subscriptionCreate",
  async (data) => {
    try {
      await logAPI("FE/subscription/v9/create", "calling subscription create api from FE", data, 200);
      const response = await AxiosInstance.post(
        `/subscription/v9/create`,
        data
      );
      await logAPI("FE/subscription/v9/create", "subscription create api success from FE", response.data, 200);
      return response.data;
    } catch (error) {
      let body = {
        status: error?.status,
        stack: error?.stack,
        name: error?.name,
        axiosMessage: error?.message,
        axiosErrorCode: error?.code,
        data: error?.config?.data,
      };
      if(error.response?.data?.message) {
        body.errorMessage = error.response.data.message;
      }
      await logAPI("FE/subscription/v9/create", "subscription create api fail from FE", body, 400);
      if (error.response) {
        throw error.response.data.message;
      } else {
        throw error;
      }
    }
  }
);

const subscriptionCreateSlice = createSlice({
  name: "subscriptionCreate",
  initialState: {
    isLoading: false,
    subscriptionCreateData: null,
    isError: false,
    errorMessage: "",
  },
  extraReducers: (builder) => {
    builder
      .addCase(subscriptionCreate.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(subscriptionCreate.fulfilled, (state, action) => {
        state.isLoading = false;
        state.subscriptionCreateData = action.payload;
        state.isError = false;
        state.errorMessage = "";
      })
      .addCase(subscriptionCreate.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.errorMessage = action.error.message;
      });
  },
});

export default subscriptionCreateSlice.reducer;
