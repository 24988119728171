import { t } from "i18next";
import React from "react";
import AppleSignin from "react-apple-signin-auth";
import { appleLogin_id } from "../../environment";
import { jwtDecode } from "jwt-decode";

const AppleLogin = ({ onSuccess, onError }) => {
  const handleAppleError = (error) => {
    if (onError) {
      onError(error);
    }
  };

  const handleAppleSuccess = (response) => {
    try {
      const { id_token } = response.authorization;
      const decodedToken = jwtDecode(id_token);
      const { email, sub: id } = decodedToken;

      if (!email) {
        handleAppleError(t("FACEBOOK_EMAIL_PERMISSION"));
      }
      onSuccess({ email, id }, "apple");
    } catch (error) {
      handleAppleError(error);
    }
  };

  return (
    <AppleSignin
      authOptions={{
        clientId: appleLogin_id,
        redirectURI: `${window.location.origin}`,
        responseType: "code",
        scope: "email name",
        usePopup: true,
      }}
      onSuccess={(response) => handleAppleSuccess(response)}
      onError={(error) => handleAppleError(error)}
      uiType={"light"}
      render={(props) => (
        <div {...props}>
          <button
            className="login-with-google-btn login-with-apple-btn"
            type="button"
          >
            {t("CONTINUE_WITH_APPLE")}
          </button>
        </div>
      )}
    />
  );
};

export default AppleLogin;