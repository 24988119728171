import { facebook_id } from "../../../environment";

export const loadFacebookSDK = () => {
    return new Promise((resolve) => {
      if (document.getElementById("custom-facebook-jdk")) {
        resolve();
        return;
      }
  
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: facebook_id, 
          cookie: true,
          xfbml: true,
          version: "v18.0", 
        });
        resolve();
      };
  
      const script = document.createElement("script");
      script.id = "custom-facebook-jdk";
      script.src = "https://connect.facebook.net/en_US/sdk.js";
      script.async = true;
      script.defer = true;
      document.body.appendChild(script);
    });
  };
  